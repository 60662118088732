<template>
    <div>
        <el-form :model="formBasic" :rules="basicRules" ref="form" size="mini">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'代码'" prop="code">
                        <el-input v-model="formBasic.code" style="width: 100%"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'父节点代码'"
                        prop="parentCode"
                    >
                        <el-input
                            v-model="formBasic.parentCode"
                            style="width: 100%"
                            :disabled="type === 'add'"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'语言类别'"
                        prop="languageCode"
                    >
                        <el-select
                            v-model="formBasic.languageCode"
                            :placeholder="'请选择'"
                            filterable
                            allow-create
                        >
                            <el-option
                                v-for="item in keyValues.languages"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'名称翻译'"
                        prop="name"
                        style="width:100%"
                    >
                        <el-input
                            v-model="privilegeLanguage.name"
                            style="width:100%"
                            @change="changeName"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'描述翻译'"
                        prop="description"
                        style="width:100%"
                    >
                        <el-input
                            type="textarea"
                            :rows="3"
                            v-model="privilegeLanguage.description"
                            style="width:100%"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'AuthManagementForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        parentId: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            result: [],
            currentTab: 'formBasic',
            formBasic: {
                code: '',
                parentCode: '',
                parentId: this.parentId,
                name: '',
                languageCode: '',
                privilegeLanguages: [
                    {languageCode: this.formBasic.languageCode, name: '', description: ''}
                ]
            },
            basicRules: {
                languageCode: [
                    {
                        required: true,
                        message: '请输入语言类别',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '请输入名称翻译',
                        trigger: 'blur'
                    }
                ],
                code: [
                    {
                        required: true,
                        message: '请输入代码',
                        trigger: 'blur'
                    }
                ],
                parentCode: [
                    {
                        required: false,
                        message: '请选择父节点代码',
                        trigger: 'blur'
                    }
                ]
            },
            keyValues: {
                languages: []
            }
        };
    },
    computed: {
        privilegeLanguage() {
            const privilegeLanguages = this.formBasic.privilegeLanguages.filter(e => e.languageCode === this.formBasic.languageCode);
            return privilegeLanguages[0];
        }
    },
    methods: {
        async bindKeyValues() {
            const data = await this.$client.getLanguages();
            this.keyValues.languages = data;
        },
        async initFormValue() {
            await this.bindKeyValues();
            await this.getPrivilegeArray();
        },
        async getPrivilegeArray() {
            this.$client.getPrivilegeTree().then(data => {
                for (let i = 0; i < data.length; i++) {
                    this.result.push({code: data[i].entity.code, id: data[i].id, parentId: data[i].parentId});
                    this.flat(data[i].children);
                }
                const {type, id, parentId} = this;
                this.formBasic.parentId = parentId;
                if ((type === 'edit' || type === 'detail') && id != null) {
                    this.getPrivilegeById(this.id);
                } else {
                    const parentCode = this.result.filter(i => i.id === parentId);
                    if (parentCode.length < 1) {
                        this.formBasic.parentCode = '';
                    } else {
                        this.formBasic.parentCode = parentCode[0].code;
                    }
                }
            });
        },
        changeName(name) {
            this.formBasic.name = name;
        },
        flat(nodes) {
            if (!nodes || nodes.length === 0) {
                return [];
            }
            nodes.forEach(node => {
                this.result.push({code: node.entity.code, id: node.id, parentId: node.parentId});
                return this.flat(node.children);
            });
        },
        getPrivilegeById(id) {
            this.$client.getPrivilegeById(id).then(data => {
                if (data.privilegeLanguages.length > 0) {
                    data.languageCode = data.privilegeLanguages[0].languageCode;
                    data.name = data.privilegeLanguages[0].name;
                } else {
                    data.languageCode = '';
                    data.privilegeLanguages = [
                        {languageCode: this.formBasic.languageCode, name: '', description: ''}
                    ];
                }
                this.formBasic = {
                    ...this.formBasic,
                    ...data
                };
                const a = this.result.filter(i => i.id === data.parentId);
                if (a.length < 1) {
                    this.formBasic.parentCode = '';
                } else {
                    this.formBasic.parentCode = a[0].code;
                }
            });
        },
        submitHandle(cb) {
            this.formBasic.parentId = '';
            if (this.formBasic.parentCode) {
                const pCode = this.result.filter(i => i.code === this.formBasic.parentCode);
                if (pCode.length < 1) {
                    this.$message({
                        message: '父节点不存在',
                        type: 'error'
                    });
                    return;
                }
                this.formBasic.parentId = pCode[0].id;
            }
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$client.createOrUpdate(this.formBasic).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
</style>
